/*
 * Copyright (C) 2022 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMutation, useQuery } from '@apollo/client';
import { styled, Theme, Typography } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';

import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import {
  Button,
  Col,
  Container,
  Form,
  FormCheckbox,
  useAlert,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

// #region Terminal GraphQL
type NotificationPreferences = {
  emailMarketing?: boolean;
};

// Terminal API Query
const NOTIFICATION_SETTINGS_QUERY = gql`
  query GetUserSemiPublicInfo($token: String!) {
    userSemiPublicInfoViaToken(token: $token) {
      email
      notification {
        emailMarketing
      }
    }
  }
`;
type GetSettingsResponse = {
  userSemiPublicInfoViaToken: {
    email: string;
    notification: NotificationPreferences;
  };
};

// Terminal API Mutation
const SET_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation UpdateSettings(
    $token: String!
    $settings: NotificationSettingsInput!
  ) {
    updateUserNotificationSettingsViaToken(token: $token, settings: $settings) {
      emailMarketing
    }
  }
`;
type UpdateSettingsResponse = {
  updateUserNotificationSettingsViaToken: NotificationPreferences;
};
// #endregion Terminal GraphQL

// #region Styled Components
const SettingsContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(25),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
  },
}));

const Spacer = styled('div')<Theme, { size?: 'thin' | 'regular' }>(
  ({ size = 'regular' }) => {
    switch (size) {
      case 'thin':
        return { height: '1em' };
      case 'regular':
      default:
        return { height: '2em' };
    }
  }
);

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));
// #endregion Styled Components

interface Props {
  data: {
    communicationPreferencesYaml: {
      metadata: MetaData;
      errors: {
        network: string;
        saving: string;
        other: string;
      };
      settings: {
        loading: string;
        email: {
          title: string;
          updates: string;
          marketing: string;
          disableReason: string;
        };
        save: string;
        saved: string;
      };
    };
  };
}

const CommunicationPreferences: React.FC<Props> = ({ data: gqlData }) => {
  const data = gqlData.communicationPreferencesYaml;

  const location = useLocation();
  const alert = useAlert();

  const { token } = queryString.parse(location.search);

  const [emailAddress, setEmailAddress] = useState('');
  const [emailMarketing, setEmailMarketing] = useState(true);

  const { error: queryError, loading } = useQuery<GetSettingsResponse>(
    NOTIFICATION_SETTINGS_QUERY,
    {
      onCompleted: ({ userSemiPublicInfoViaToken: result }) => {
        setEmailAddress(result.email);
        setEmailMarketing(result.notification.emailMarketing ?? true);
      },
      variables: { token },
    }
  );

  const [savePreferences, { error: mutationError }] =
    useMutation<UpdateSettingsResponse>(SET_NOTIFICATION_SETTINGS_MUTATION, {
      onCompleted: ({ updateUserNotificationSettingsViaToken: result }) => {
        setEmailMarketing(result.emailMarketing ?? true);
        alert.success(data.settings.saved);
      },
    });

  const saveChanges = () => {
    savePreferences({ variables: { settings: { emailMarketing }, token } });
  };

  const queryErrorMessage = useMemo(() => {
    if (!queryError) return '';
    if (queryError.networkError) return data.errors.network;
    return data.errors.other;
  }, [data.errors.network, data.errors.other, queryError]);

  useEffect(() => {
    if (mutationError) {
      alert.error(data.errors.saving);
    }
  }, [alert, data.errors.saving, mutationError]);

  return (
    <Overflow>
      <Layout
        title={data.metadata.title}
        description={data.metadata.description}
      >
        <SettingsContainer>
          <Col md={3} />
          <Col md={6} sm={12}>
            <Typography variant="h3">{data.metadata.title}</Typography>
            <Spacer />

            {loading ? (
              <Typography>{data.settings.loading}</Typography>
            ) : queryErrorMessage ? (
              <ErrorText>{queryErrorMessage}</ErrorText>
            ) : (
              <>
                <Form>
                  <Typography variant="h4">
                    {data.settings.email.title}
                  </Typography>
                  <Typography variant="caption">{emailAddress}</Typography>
                  <Spacer size="thin" />

                  <FormCheckbox
                    name="emailUpdates"
                    checked
                    disabled
                    onChange={() => {
                      // Stub because onChange is required.
                    }}
                  >
                    <Label>{data.settings.email.updates}</Label>
                    <Description>
                      {data.settings.email.disableReason}
                    </Description>
                  </FormCheckbox>

                  <Spacer size="thin" />

                  <FormCheckbox
                    name="emailMarketing"
                    checked={emailMarketing}
                    onChange={(e) => setEmailMarketing(e.target.checked)}
                  >
                    <Label>{data.settings.email.marketing}</Label>
                  </FormCheckbox>
                </Form>
                <Spacer />
                <Button onClick={saveChanges}>{data.settings.save}</Button>
              </>
            )}
          </Col>
        </SettingsContainer>
      </Layout>
    </Overflow>
  );
};

export const query = graphql`
  query CommPrefQuery {
    communicationPreferencesYaml {
      metadata {
        title
        description
      }
      errors {
        network
        saving
        other
      }
      settings {
        loading
        email {
          title
          updates
          marketing
          disableReason
        }
        save
        saved
      }
    }
  }
`;

export default CommunicationPreferences;
